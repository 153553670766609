@import "../../common-styles/sv-list.scss";

.sd-list {
  padding-right: calcSize(0.5);
  padding-left: calcSize(0.5);
}

.sd-list__item.sd-list__item {
  padding: 0;
  .sd-list__item-body {
    padding-top: calcSize(1.5);
    padding-bottom: calcSize(1.5);
    border-radius: 3px;
  }
}

.sd-list__item--selected.sd-list__item--selected .sd-list__item-body {
  background-color: $primary-light;
  color: $foreground;
}

.sd-list__item--selected {

  &:hover,
  &:focus {
    .sd-list__item-body {
      background-color: $primary;
      color: $primary-foreground;
    }
  }
}